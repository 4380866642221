<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <!-- Content Left -->
    <b-col
      md="6"
      class="content-header-left mb-2"
    >
      <b-row class="breadcrumbs-top">
        <b-col md="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $t($route.meta.pageTitle) }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="{ name: item.to }"
              >
                {{ $t(item.text) }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- v-can="$route.meta.permissions" -->
    <b-col
      v-if="$route.meta.hasCreateButton"
      class="content-header-right text-md-right mb-1"
      md="6"
    >
      <b-button
        variant="primary"
        class="float-right"
        size="small"
        @click="$root.$emit('add')"
      >
        <feather-icon icon="PlusIcon" />
        {{ $route.meta.createButtonName }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
}
</script>
