<template>
  <b-modal
    v-model="visible"
    no-close-on-backdrop
    size="xl"
    hide-footer
    centered
    :title="$t('Mavjud mahsulotlarni izlang')"
    @close="$emit('close', true)"
    @cancel="$emit('close', true)"
  >
    <ScanerProduct
      is-component
      @close="close"
    />
  </b-modal>
</template>

<script>
import ScanerProduct from '@/components/ScanerProduct.vue'

export default {
  name: 'Create',
  components: { ScanerProduct },
  props: {
  },
  data() {
    return {
      loading: false,
      title: '',
      visible: false,
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.visible = true

    this.$root.$on('add', data => {
      this.visible = true
    })
  },
  methods: {
    close(data) {
      this.visible = false
      this.$emit('close', data)
    },
  },
}
</script>

<style scoped></style>
