<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template
      v-if="notificationsObj"
      #button-content
    >
      <feather-icon
        :badge="notificationCount"
        :badge-classes="`bg-openid`"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>
    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header">
        <!-- <h4 class="notification-title mb-0 mr-auto">
          Bildirishnomalar
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{
            notificationsObj
              ? notificationCount + " ta"
              : "Mavjud emas"
          }}

        </b-badge> -->
        <template v-if="notificationsObj && (isOrgDepartment || isAdmin || isDirector)">
          <h4
            class="d-flex justify-content-between c-link"
            @click="viewTask"
          ><b>Tekshirishga yuborilgan: </b> <b-badge
            pill
            variant="light-primary"
          >
            {{ notificationsObj.to_testing }}
          </b-badge></h4>
        </template>
        <template v-else-if="notificationsObj">
          <h4
            class="d-flex justify-content-between c-link"
            @click="viewTask"
          ><b>Yangi: </b> <b-badge
            pill
            variant="light-primary"
          >
            {{ notificationsObj.new }}
          </b-badge></h4>
          <h4
            class="d-flex justify-content-between c-link"
            @click="viewTask"
          ><b>Qabul qilingan: </b> <b-badge
            pill
            variant="light-primary"
          >
            {{ notificationsObj.received }}
          </b-badge></h4>
          <h4
            class="d-flex justify-content-between c-link"
            @click="viewTask"
          ><b>Muddati o'tgan: </b> <b-badge
            pill
            variant="light-primary"
          >
            {{ notificationsObj.expired }}
          </b-badge></h4>
          <h4
            class="d-flex justify-content-between c-link"
            @click="viewTask"
          ><b>Rad etilgan: </b> <b-badge
            pill
            variant="light-primary"
          >
            {{ notificationsObj.rejected }}
          </b-badge></h4>
        </template>
      </div>
    </li>

    <!-- Cart Footer -->
    <li
      v-if="notificationsObj && notificationsObj.count"
      class="dropdown-menu-footer"
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="viewTask"
      >Barchasini o'qish</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import { getToken } from '@/utils/auth'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      notificationsObj: null,
    }
  },
  computed: {
    notificationCount() {
      if (this.isOrgDepartment || this.isAdmin || this.isDirector) {
        return this.notificationsObj.to_testing
      }
      return this.notificationsObj.expired + this.notificationsObj.new + this.notificationsObj.received + this.notificationsObj.rejected
    },
  },
  mounted() {
    this.getItemsAction().then(res => {
      this.notificationsObj = res.data
    })
  },
  methods: {
    ...mapActions({
      getItemsAction: 'user/getUserTasksCount',
    }),
    viewTask() {
      const token = getToken()
      window.open(`https://kanban.salomhayot.uz/#/auth?token=${token}`)
      // this.$router.push('/')
    },
  },
  setup() {
    /* eslint-disable global-require */
    const notifications = [
      {
        title: 'Congratulation Sam 🎉',
        avatar: require('@/assets/images/avatars/6-small.png'),
        subtitle: 'Won the monthly best seller badge',
        type: 'light-success',
      },
      {
        title: 'New message received',
        avatar: require('@/assets/images/avatars/9-small.png'),
        subtitle: 'You have 10 unread messages',
        type: 'light-info',
      },
      {
        title: 'Revised Order 👋',
        avatar: 'MD',
        subtitle: 'MD Inc. order updated',
        type: 'light-danger',
      },
    ]
    /* eslint-disable global-require */

    const systemNotifications = [
      {
        title: 'Server down',
        subtitle: 'USA Server is down due to hight CPU usage',
        type: 'light-danger',
        icon: 'XIcon',
      },
      {
        title: 'Sales report generated',
        subtitle: 'Last month sales report generated',
        type: 'light-success',
        icon: 'CheckIcon',
      },
      {
        title: 'High memory usage',
        subtitle: 'BLR Server using high memory',
        type: 'light-warning',
        icon: 'AlertTriangleIcon',
      },
    ]

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      notifications,
      systemNotifications,
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>
</style>
